//路由文件
import {
    createRouter,
    createWebHistory
} from 'vue-router'

const DetailForm = () => import('@/components/DetailEnterprises/DetailEnterprises.vue')
const RatedForm = () => import('@/components/RatedFormContainer/RatedFormContainer.vue')
const FunctionPage = () => import('@/views/FunctionPage/FunctionPage.vue')
const LoginPage = () => import('@/views/LoginPage/LoginPage.vue')
const ForgetPassword = () => import('@/components/ForgetPassword/ForgetPassword.vue')
const CompanyList = () => import('@/components/CompanyList/companyList.vue')
const Test = () => import('@/components/Test/ForTest.vue')
const UserForm = () => import('@/components/UserForm/UserForm.vue')
const UserRatedForm = () => import('@/components/UserRatedForm/UserRatedForm.vue')
const SalaryStatistics = () => import('@/components/SalaryStatistics/SalaryStatistics.vue')
const SalaryDetail = () => import('@/components/SalaryStatistics/SalaryDetail.vue')
const EditForm = () => import('@/components/EditContainer/EditContainer.vue')
const ReDispatchOrder = () => import('@/components/ReDispatchOrder/ReDispatchOrder.vue')
const TodoCompany = () => import('@/components/TodoCompany/TodoCompany.vue')
const EvaluatedCompany = () => import('@/components/EvaluatedCompany/EvaluatedCompany.vue')
const ReevaluateCompany = () => import('@/components/ReevaluateCompany/ReevaluateCompany.vue')
const FinishCompany = () => import('@/components/FinishCompany/FinishCompany.vue')
const CompanyStatus = () => import('@/components/CompanyStatus/CompanyStatus.vue')

const routes = [
    {
        path: '/',
        component: LoginPage
    },
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/forgetPassword',
        component: ForgetPassword
    },
    {
        path: '/funcs',
        component: FunctionPage,
        children: [
            {
                path: 'evaluatedCompany',
                component: EvaluatedCompany
            },
            {
                path: 'todoCompany',
                component: TodoCompany
            },
            {
                path: 'ratedform',
                component: RatedForm
            },
            {
                path: 'detailform',
                component: DetailForm
            },
            {
                path: 'companyList',
                component: CompanyList
            },
            {
                path: 'userForm',
                component: UserForm
            },
            {
                path: 'userRatedForm',
                component: UserRatedForm
            },
            {
                path: 'salaryStatistics',
                component: SalaryStatistics
            },
            {
                path: 'editForm/:id',
                component: EditForm
            },
            {
                path: 'salaryDetail',
                component: SalaryDetail
            },
            {
                path: 'reDispatchOrder',
                component: ReDispatchOrder
            },
            {
                path: 'reevaluateCompany',
                component: ReevaluateCompany
            },
            {
                path: 'finishCompany',
                component: FinishCompany
            },
            {
                path: 'companyStatus',
                component: CompanyStatus
            }
        ]
    },
    {
        path: '/test',
        component: Test
    },
]

//创建并创建路由器
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router